import { useContext, useState } from 'react';
import { TrackingContext } from "../tracking";
import { GrowthBook } from "@growthbook/growthbook";
import { GrowthBookProvider } from '@growthbook/growthbook-react';
export const ExperimentsInitializer = ({ children }) => {
    const { actions } = useContext(TrackingContext);

    if (!actions) {
        return null;
    }

    const gb = new GrowthBook({
        apiHost: "https://cdn.growthbook.io",
        clientKey: "sdk-SMap1vxuQnScRncD",
        enableDevMode: false,
        attributes: {
            id: '1234'
        },
        trackingCallback: (experiment, result) => {
            actions.experiment(experiment, result)
        },
    });

    gb.init({
        streaming: true
    });

    return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};